import _defineProperty from "/home/monitor/code/btrunk/buoyancyinfo_webclient/login/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _PORT_CONFIG;

var VERSION = '0.0.1'; // export const HOST = 'http://129.211.114.250/gateway';

export var HOST = 'http://192.168.0.222/gateway'; // export const HOST = 'http://192.168.0.121/gateway';
// export const HOST = 'http://192.168.0.136/gateway';
// export const HOST = 'http://129.211.143.35/gateway';
// export const HOST = 'https://www.taxlab.com.cn/gateway';
// export const HOST = 'http://1.15.91.60/gateway';

export var PORT_CONFIG = (_PORT_CONFIG = {
  login: 3000
}, _defineProperty(_PORT_CONFIG, "login", {
  port: 3000,
  ssoLogin: '',
  serverUrl: HOST,
  Redirect: 'http://localhost:5000/#'
}), _defineProperty(_PORT_CONFIG, "main_frame", 5000), _defineProperty(_PORT_CONFIG, "archeivement", 8001), _defineProperty(_PORT_CONFIG, "main_frame", {
  port: 5000,
  redirect: 'http://localhost:3000',
  iframePath: 'http://localhost:8000'
}), _defineProperty(_PORT_CONFIG, "configurable_resource_view", 8000), _defineProperty(_PORT_CONFIG, "view_config", 3001), _defineProperty(_PORT_CONFIG, "module_config", 3002), _defineProperty(_PORT_CONFIG, "authority", 3003), _defineProperty(_PORT_CONFIG, "demo", 3004), _defineProperty(_PORT_CONFIG, "function_configuration", 3005), _defineProperty(_PORT_CONFIG, "importfieldmapcontrol", 3006), _defineProperty(_PORT_CONFIG, "module_config_extend", 3007), _defineProperty(_PORT_CONFIG, "preview", 3008), _defineProperty(_PORT_CONFIG, "progress_socket", 3009), _defineProperty(_PORT_CONFIG, "report_view", 8888), _defineProperty(_PORT_CONFIG, "stepeditor", 3011), _defineProperty(_PORT_CONFIG, "template_config", 3012), _defineProperty(_PORT_CONFIG, "view_config_extend", 3013), _defineProperty(_PORT_CONFIG, "work_flow", 3014), _defineProperty(_PORT_CONFIG, "crv_web_config", 3015), _defineProperty(_PORT_CONFIG, "crv_form_config", 3016), _defineProperty(_PORT_CONFIG, "crv_model_view", 3017), _PORT_CONFIG);
export var isDev = process && process.env.NODE_ENV === 'development';
export var originAndHostname = "".concat(location.protocol, "//localhost");
export var getRealUrl = function getRealUrl(url) {
  if (!isDev || url.match(/^http(s?):\/\//)) {
    return url;
  }

  var realUrl = url.replace(/^\/[^/]+/, function (slashProjectName) {
    var projectName = slashProjectName.slice(1);
    var value = PORT_CONFIG[projectName];
    var port = typeof value === 'object' ? value.port : value;
    return port ? "".concat(originAndHostname, ":").concat(port).concat(slashProjectName) : slashProjectName;
  });
  console.log('realUrl', realUrl);
  return realUrl;
};

window.getAttribute = window.getAttribute || function (key) {
  if (isDev) {
    var config = {
      redirect: PORT_CONFIG.main_frame.redirect,
      Redirect: PORT_CONFIG.login.Redirect,
      ssoLogin: PORT_CONFIG.login.ssoLogin,
      host: HOST,
      serverUrl: HOST
    };
    return config[key] || '';
  }

  return document.getElementsByTagName('body')[0].getAttribute(key);
};

export var convertHttpPath = function convertHttpPath(path) {
  var _path = getRealUrl(path);

  return _path.match(/^http(s?):\/\//) ? _path : "".concat(location.origin).concat(_path);
};